import { CmsYoutubeVideo } from "../types/cms";

const YoutubeVideo = ({ title, videoId }: CmsYoutubeVideo) => (
  <div className="aspect-w-16 aspect-h-9">
    <iframe
      title={title}
      src={`https://www.youtube.com/embed/${videoId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="w-full aspect-video"
    />
  </div>
);

export default YoutubeVideo;
