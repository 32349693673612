import React from "react";
import CMS from "netlify-cms-app";
import { PreviewTemplateComponentProps } from "netlify-cms-core";
import Page from "./components/Page";

const PagePreview: React.FC<PreviewTemplateComponentProps> = ({
  entry,
  window,
}) => {
  const rawBody = entry.getIn(["data", "body"]);
  if (rawBody === undefined) {
    return (
      <div
        style={{
          textAlign: "center",
          width: "50%",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        Redigera innehållet till vänster för att se förhandsvisningen här.
      </div>
    );
  }
  const body = entry.getIn(["data", "body"]).toJS();
  const title = entry.getIn(["data", "title"]);
  return (
    <main
      style={{ backgroundColor: "white" }}
      className="container px-8 mx-auto xl:px-5 max-w-screen-lg py-5 lg:py-8"
    >
      <div className="max-w-2x1 mx-auto bg-background">
        <Page title={title} body={body} preview />
      </div>
    </main>
  );
};

export default function Admin() {
  CMS.init();
  CMS.registerPreviewTemplate("pages", PagePreview);
  CMS.registerPreviewStyle("/admin.css");
  return <div id="nc-root" />;
}
