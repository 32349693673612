import React, { ReactNode } from "react";
import Image from "./Image";
import Gallery from "./Gallery";
import { CmsBlock } from "../types/cms";
import StyledMarkdown from "./StyledMarkdown";
import cx from "classnames";
import Alignable from "../types/alignable";
import YoutubeVideo from "./YoutubeVideo";
import manifest from "../data.json";

type ContentPropsType = {
  data: CmsBlock | CmsBlock[];
  depth: number | undefined;
  onDark?: boolean;
  preview?: boolean;
};

const ContentSection: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
    type: string;
    preview: boolean;
  }
> = ({ children, type, preview, ...props }) => {
  return (
    <section
      {...props}
      style={
        preview
          ? {
              position: "relative",
              border: "1px dashed #CCCCCC",
              borderRadius: "4px",
              padding: "4px",
            }
          : undefined
      }
    >
      {preview && (
        <div
          className="text-xs text-gray-600"
          style={{
            background: "#CCCCCCA0",
            borderRadius: "9999px",
            position: "absolute",
            top: 0,
            left: "5px",
            transform: "translateY(-50%)",
            padding: "1px 8px",
          }}
        >
          {(manifest as any).types[type]}
        </div>
      )}
      {children}
    </section>
  );
};

const Content: React.FC<ContentPropsType & Alignable> = ({
  data,
  depth = 0,
  align,
  onDark = false,
  preview = false,
}) => {
  const alignClass = align ? `align-${align}` : "align-left";

  if (Array.isArray(data)) {
    return data.map((content, i) => (
      <Content
        key={`field-${i}`}
        data={content}
        depth={depth}
        align={align}
        onDark={onDark}
        preview={preview}
      />
    ));
  }

  let heading: ReactNode = null;

  if (data.heading) {
    switch (depth) {
      case 0:
        heading = (
          <h2
            className={`text-2xl font-headline text-headline text-light-headline`}
          >
            {data.heading}
          </h2>
        );
        break;

      case 1:
        heading = (
          <h3
            className={`text-xl font-headline text-headline text-light-headline`}
          >
            {data.heading}
          </h3>
        );
        break;
      case 2:
        heading = (
          <h4
            className={`text-lg font-headline text-headline text-light-headline`}
          >
            {data.heading}
          </h4>
        );
        break;
      default:
        heading = (
          <h5
            className={`text-base font-headline text-headline text-light-headline`}
          >
            {data.heading}
          </h5>
        );
        break;
    }
  } else {
    heading = <></>;
  }

  switch (data.type) {
    case "text":
      return (
        <ContentSection
          className={cx("my-5 w-full", alignClass)}
          type={data.type}
          preview={preview}
        >
          {heading}
          <StyledMarkdown onDark={onDark}>{data.body}</StyledMarkdown>
        </ContentSection>
      );
    case "image":
      return (
        <ContentSection
          className={cx("my-5", alignClass)}
          type={data.type}
          preview={preview}
        >
          {heading}
          <Image {...data} />
        </ContentSection>
      );
    case "gallery":
      return (
        <ContentSection
          className={cx("my-5 w-full", alignClass)}
          type={data.type}
          preview={preview}
        >
          {heading}
          <Gallery {...data} />
        </ContentSection>
      );
    case "youtube":
      return (
        <ContentSection
          className={cx("my-5 w-full", alignClass)}
          type={data.type}
          preview={preview}
        >
          {heading}
          <YoutubeVideo {...data} />
        </ContentSection>
      );
    case "columns":
      const alignLeftClass = data.leftAlign
        ? `align-${data.leftAlign}`
        : "align-left";
      const alignRightClass = data.rightAlign
        ? `align-${data.rightAlign}`
        : "align-left";
      return (
        <ContentSection
          className={cx("my-5 w-full", alignClass)}
          type={data.type}
          preview={preview}
        >
          {heading}
          <div className="flex flex-wrap gap-10">
            <div className={cx("flex-1 min-w-[200px]", alignLeftClass)}>
              {data.left && (
                <Content
                  data={data.left}
                  depth={depth + 1}
                  align={data.leftAlign}
                  preview={preview}
                />
              )}
            </div>
            <div className={cx("flex-1 min-w-[200px]", alignRightClass)}>
              {data.right && (
                <Content
                  data={data.right}
                  depth={depth + 1}
                  align={data.rightAlign}
                  preview={preview}
                />
              )}
            </div>
          </div>
        </ContentSection>
      );
    default:
      console.log(
        "Content.tsx, data.type",
        (data as CmsBlock).type,
        "not implemented. Data:",
        data
      );
      return null;
  }
};

export default Content;
