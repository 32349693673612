import React from "react";
import Content from "./Content";
import { BorderedHeading } from "./BorderedHeading";

type PagePropsType = {
  title: string;
  subtitle?: string;
  body: any;
  children?: React.ReactNode;
  preview?: boolean;
};

const Page = ({
  title,
  subtitle = undefined,
  body,
  children,
  preview = false,
}: PagePropsType) => (
  <div
    className={`pl-${preview ? 1 : 12} pr-${preview ? 1 : 12}${
      preview ? " preview" : ""
    }`}
  >
    <div className="mb-5">
      <BorderedHeading large>
        <h1 className="pt-2 w-full md:w-auto text-3xl font-headline text-light-headline lg:text-4xl">
          {title}
        </h1>
      </BorderedHeading>
    </div>
    {subtitle && (
      <div className="text-center">
        <p className="text-lg">{subtitle}</p>
      </div>
    )}
    <Content data={body} depth={0} preview={preview} />
    {children}
  </div>
);

export default Page;
