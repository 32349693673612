import React from "react";
import { Link } from "react-router-dom";
import { CmsPage } from "../types/cms";

type HeaderProps = {
  siteTitle: string;
  startPage?: CmsPage;
  eventsPageTitle?: string;
  newsPageTitle?: string;
  pages?: CmsPage[];
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
};

const organizePages = (pages: CmsPage[]) => {
  const topLevelPages: CmsPage[] = [];
  const childPages: { [key: string]: CmsPage[] } = {};

  pages
    .filter((p) => p.showInMenu)
    .sort((a, b) => a.order - b.order)
    .forEach((page) => {
      if (!page.parentPage) {
        topLevelPages.push(page);
      } else {
        if (!childPages[page.parentPage]) {
          childPages[page.parentPage] = [];
        }
        childPages[page.parentPage].push(page);
      }
    });

  return { topLevelPages, childPages };
};

const SubmenuDropdown = ({
  parentTitle,
  parentSlug,
  children,
}: {
  parentTitle: string;
  parentSlug: string;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div
      className="relative group flex items-center gap-1"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Link
        to={`/${parentSlug}`}
        className="text-md font-medium text-light hover:text-light-accent py-2"
      >
        {parentTitle}
      </Link>
      <button
        className="p-1 text-light hover:text-light-accent"
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Toggle submenu"
      >
        <svg
          className={`w-4 h-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <div
        className={`absolute left-0 top-full w-48 bg-light-secondary rounded-md shadow-lg py-1 z-50 transition-opacity duration-150 ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

const MobileSubmenu = ({
  parentTitle,
  children,
}: {
  parentTitle: string;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="w-full">
      <button
        className="w-full px-5 py-2 text-lg font-medium text-light hover:text-light-accent flex items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        {parentTitle}
        <svg
          className={`w-4 h-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && <div className="pl-4">{children}</div>}
    </div>
  );
};

const Header = ({
  siteTitle,
  startPage,
  pages = [],
  isMenuOpen,
  setIsMenuOpen,
}: HeaderProps) => {
  const { topLevelPages, childPages } = React.useMemo(
    () => organizePages(pages),
    [pages]
  );

  const renderDesktopMenu = () => (
    <div className="hidden md:flex justify-after items-center gap-10">
      {topLevelPages.map((page) =>
        childPages[page.title] ? (
          <SubmenuDropdown
            key={page.slug}
            parentTitle={page.title}
            parentSlug={page.slug}
          >
            {childPages[page.title].map((childPage) => (
              <Link
                key={childPage.slug}
                to={`/${childPage.slug}`}
                className="block px-4 py-2 text-md text-light hover:text-light-accent"
              >
                {childPage.title}
              </Link>
            ))}
          </SubmenuDropdown>
        ) : (
          <Link
            key={page.slug}
            to={`/${page.slug}`}
            className="text-md font-medium text-light hover:text-light-accent"
          >
            {page.title}
          </Link>
        )
      )}
    </div>
  );

  const renderMobileMenu = () => (
    <dialog
      open={isMenuOpen}
      className="fixed top-0 start-0 end-0 bottom-0 w-full h-full backdrop-blur"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      onClick={() => setIsMenuOpen(false)}
    >
      <div
        className="flex flex-col items-left justify-left bg-light-secondary"
        onClick={(e) => e.stopPropagation()}
      >
        {topLevelPages.map((page) =>
          childPages[page.title] ? (
            <MobileSubmenu key={page.slug} parentTitle={page.title}>
              {childPages[page.title].map((childPage) => (
                <Link
                  key={childPage.slug}
                  to={`/${childPage.slug}`}
                  className={`block px-5 py-2 text-lg font-medium ${
                    window.location.pathname === `/${childPage.slug}`
                      ? "text-light-primary"
                      : "text-light hover:text-light-accent"
                  }`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {childPage.title}
                </Link>
              ))}
            </MobileSubmenu>
          ) : (
            <Link
              key={page.slug}
              to={`/${page.slug}`}
              className={`px-5 py-2 text-lg font-medium ${
                window.location.pathname === `/${page.slug}`
                  ? "text-light-primary"
                  : "text-light hover:text-light-accent"
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              {page.title}
            </Link>
          )
        )}
      </div>
    </dialog>
  );

  return (
    <header className="container mx-auto max-w-screen-lg">
      <div className="flex justify-left pb-5 bg-light-secondaryLight">
        <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl p-5 text-light-textOnSecondary break-words max-w-full">
          {siteTitle}
        </h1>
      </div>
      <nav className="px-8 xl:px-5 p-5 lb:py-8 bg-light-secondary">
        <div className="md:hidden flex justify-between items-center">
          {startPage && (
            <>
              <Link
                to="/"
                className="text-md font-medium text-light hover:text-light-accent"
              >
                {startPage.title}
              </Link>
              <button
                aria-label="Toggle Menu"
                className="ml-auto rounded-md px-2 py-1 text-light focus:text-light-accent focus:outline-none md:hidden"
                onClick={(e) => {
                  setIsMenuOpen(true);
                  e.stopPropagation();
                }}
              >
                <svg
                  className="h-6 w-6 fill-light-text"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                  ></path>
                </svg>
              </button>
            </>
          )}
        </div>
        {renderDesktopMenu()}
        {renderMobileMenu()}
      </nav>
    </header>
  );
};

export default Header;
